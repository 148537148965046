import type { EventAction } from '@olaisaac/event-dispatcher-sdk';

import type {
  ChosenPaymentPlanMethods,
  ReceivableTypeV2,
} from '@monorepo/interfaces';

export type Applications =
  | 'auth'
  | 'fidc-confirmation'
  | 'insurance'
  | 'matricula-app'
  | 'meu-isaac'
  | 'negotiation'
  | 'pre-matricula';

export enum EventName {
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  PAGE_VIEWED = 'PAGE_VIEWED',
  BANNER_CLICKED = 'BANNER_CLICKED',
  TAB_CLICKED = 'TAB_CLICKED',
  CARD_CLICKED = 'CARD_CLICKED',
  CHECK_SELECTED = 'CHECK_SELECTED',
  CHECK_UNSELECTED = 'CHECK_UNSELECTED',
  LOADING_ERROR = 'LOADING_ERROR',
  MODAL_VIEWED = 'MODAL_VIEWED',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  ROW_CLICKED = 'ROW_CLICKED',
  CHECKBOX_CLICKED = 'CHECKBOX_CLICKED',
  TOAST_VIEWED = 'TOAST_VIEWED',
  ALERT_VIEWED = 'ALERT_VIEWED',
}

export enum EventScope {
  HomePage = 'Tela inicial',
  Negotiation = 'Negociacao',
  InstallmentDetails = 'Detalhes da fatura',
  CreditCardFlow = 'Fluxo do cartao de credito',
  Invoice = 'Fatura',
  EmbeddedCA = 'embedded in CA',
  Matricula = 'Matrícula',
  TeachingMaterial = 'Material Didático',
  LOGIN = 'LOGIN',
  DATA_COLLECTION = 'COLETA_DADOS_ALUNOS',
}

export type SendEventInput = {
  event: string;
  action?: EventAction;
  scope?: string;
  entity?: string;
  pageName?: string;
  identifierName?: string;
  experiment?: {
    version?: string;
  };
} & (
  | {
      app: Extract<Applications, 'fidc-confirmation' | 'insurance'>;
      data?: undefined;
    }
  | {
      app: Extract<Applications, 'auth'>;
      data?: AuthEventData;
    }
  | {
      app: Extract<Applications, 'matricula-app'>;
      data?: EnrollmentsEventData;
    }
  | {
      app: Extract<Applications, 'meu-isaac'>;
      data?: MeuIsaacEventData;
    }
  | {
      app: Extract<Applications, 'negotiation'>;
      data?: NegotiationEventData | TeachingMaterialEventData;
    }
  | {
      app: Extract<Applications, 'pre-matricula'>;
      data?: PreEnrollmentEventData;
    }
);

export type EventContextData = {
  mutate: (input: SendEventInput) => void;
  isReady: boolean;
};

export type UserArgs = {
  user_id: string;
};

export type CampaignArgs = {
  utm_source?: string | string[];
  utm_medium?: string | string[];
  utm_campaign?: string | string[];
  utm_content?: string | string[];
};

export type EventData = {
  name?: string;
  button_name?: string;
  context?: string;
  page_name?: string;
};

export type AuthEventData = EventData;

export type PreEnrollmentEventData = {
  name?: string;
  button_name?: string;
  page_name?: string;
  students?: number;
  products?: number;
};
export type TeachingMaterialEventData = {
  button_name?: string;
  page_name?: string;
  card_name?: string;
  banner_status?: string;
  student_id?: string;
  product_id?: string;
  teaching_material?: boolean;
};

export type EnrollmentsEventData = {
  banner?: string;
  studentId?: string;
  installmentId?: string;
  button_name?: string;
  page_name?: string;
};

export type MeuIsaacEventData = {
  user_id?: string;
  installment_id?: string;
  installments_ids?: string[];
  agreement_id?: string;
  agreements_ids?: string[];
  agreement_order?: number;
  total_agreements?: number;
  category?: 'meu-isaac';
  installment_plan?: string;
  payment_method?: 'credit-card' | 'invoice';
  type?: string;
  receivableType?: ReceivableTypeV2;
  selected_payment_method?: ChosenPaymentPlanMethods;
  offer_entry_value?: number;
  offer_number_of_installments?: number;
  selected_entry_value?: number;
  selected_number_of_installments?: number;
  offer_due_date?: string;
  selected_due_date?: string;
  api_version?: 'v2' | 'v1';
  page_name?: string;
  button_name?: string;
  modal_name?: string;
  contact_change?: string;
  embedded?: string;
  quantity_students?: number;
};

export type NegotiationEventData = {
  user_id?: string;
  installment_id?: string;
  installments_ids?: string[];
  agreement_id?: string;
  agreements_ids?: string[];
  agreement_order?: number;
  total_agreements?: number;
  category?: 'meu-isaac';
  installment_plan?: string;
  payment_method?: 'credit-card' | 'invoice';
  type?: string;
  receivable_type?: ReceivableTypeV2;
  selected_payment_method?: ChosenPaymentPlanMethods;
  offer_entry_value?: number;
  offer_number_of_installments?: number;
  selected_entry_value?: number;
  selected_number_of_installments?: number;
  offer_due_date?: string;
  selected_due_date?: string;
  api_version?: 'v2';
  contact_change?: string;
} & EventData;

export enum MeuIsaacEvents {
  VIEW_CA_INTRO_PAGE = 'view_ca_intro_page',
  VIEW_CA_ERROR_PAGE = 'view_ca_error_page',
  CLICK_GO_TO_HOME = 'click_go_to_home',
  VIEWD_TERMS = 'viewed_terms',
  CLICK_BACK_TERMS = 'click_back_terms',
}

export enum MeuIsaacPageNames {
  CA_INTRO_PAGE = 'CA_INTRO_PAGE',
  CA_ERROR_PAGE = 'CA_ERROR_PAGE',
  TERMS = 'TERMS',
  DATA_COLLECTION = 'DADOS_CADASTRAIS_DO_ALUNO',
  DATA_COLLECTION_LIST = 'LISTA_DE_ALUNOS_PARA_ATUALIZACAO',
}

export enum MeuIsaacModalNames {
  DUPLICATE_DATA_ALERT = 'ALERTA_DE_DUPLICAÇÃO_DE_DADOS',
}

export enum AuthEvents {
  CLICK_SEND_LINK_BY_SMS = 'click_send_link_by_sms',
  CLICK_SEND_LINK_BY_EMAIL = 'click_send_link_by_email',
  CLICK_WPP = 'click_wpp',
  CLICK_CHANGE_CONTACT = 'click_change_contact',
  CLICK_LOGIN = 'click_login',
  CLICK_INITIAL_LOGIN = 'click_initial_login',
  FORGOT_PASSWORD = 'forgot_password',
  ACCESS_WITHOUT_PASSWORD = 'access_without_password',
  RESEND_LINK = 'resend_link',
  CLICK_CHANGE_PHONE = 'click_change_phone',
  CLICK_CHANGE_EMAIL = 'click_change_email',
  UPDATE_PHONE = 'update_phone',
  UPDATE_EMAIL = 'update_email',
  UPDATE_EMAIL_AND_PHONE = 'update_email_and_phone',
  ACCESS_BLOCKED = 'access_blocked',
  MAGIC_LINK_ERROR = 'magic_link_error',
}

export enum AuthPageNames {
  UPDATE_CONTACT = 'ALTERACAO_DE_CONTATO',
  NO_UPDATE_CONTACT = 'CONTATO_NAO_ALTERADO',
  LOGIN_WITHOUT_PASSWORD = 'ENTRAR_SEM_SENHA',
  FORGOT_PASSWORD = 'ESQUECI_A_SENHA',
  FIRST_ACCESS = 'PRIMEIRO_ACESSO',
  LOGIN = 'LOGIN',
  SUCCESS_UPDATE_CONTACT_LINK_EMAIL = 'SUCESSO_ATUALIZACAO_CONTATO_POR_EMAIL',
  SUCCESS_UPDATE_CONTACT_LINK_SMS = 'SUCESSO_ATUALIZACAO_CONTATO_POR_SMS',
  SUCCESS_UPDATE_CONTACT_EMAIL = 'SUCESSO_ATUALIZACAO_CONTATO_EMAIL',
  SUCCESS_UPDATE_CONTACT_SMS = 'SUCESSO_ATUALIZACAO_CONTATO_SMS',
  LINK_LOGIN_WITHOUT_PASSWORD_EMAIL = 'LINK_ENTRAR_SEM_SENHA_EMAIL',
  LINK_LOGIN_WITHOUT_PASSWORD_SMS = 'LINK_ENTRAR_SEM_SENHA_SMS',
  LINK_FORGOT_PASSWORD_EMAIL = 'LINK_ESQUECI_A_SENHA_EMAIL',
  LINK_FORGOT_PASSWORD_SMS = 'LINK_ESQUECI_A_SENHA_SMS',
  FIRST_ACCESS_EMAIL = 'PRIMEIRO_ACESSO_EMAIL',
  FIRST_ACCESS_SMS = 'PRIMEIRO_ACESSO_SMS',
}

export enum InsurancePageNames {
  ERROR_PAGE = 'INSURANCE_ERROR',
  INTRO_PAGE = 'INSURANCE_INTRO',
  REVIEW_DATA_PAGE = 'INSURANCE_REVIEW',
  INSURANCE_REDIRECT = 'INSURANCE_REDIRECT',
  INSURANCE_POLICY = 'INSURANCE_POLICY',
}

export enum InsuranceEvents {
  ERROR_PAGE = 'error_page',
  ERROR_PAGE_GO_BACK_HOME = 'error_page_go_back_home',
  INTRO_PAGE = 'intro_page',
  DOWNLOAD_POLICY = 'download_policy',
  REDIRECT_TO_INSURANCE_PAGE = 'redirect_to_insurance_page',
  CLICK_GO_TO_CLAIM_INSURANCE = 'click_go_to_claim_insurance',
  REVIEW_DATA_PAGE = 'review_data_page',
  GO_TO_FAQ = 'go_to_faq',
  INSURED_ACCORDION = 'insured_accordion',
  NOT_INSURED_ACCORDION = 'not_insured_accordion',
  CLAIM_INSURANCE = 'claim_insurance',
}

export enum NegotiationEvents {
  CLICK_DETAILS_PROPOSAL_CHECKOUT = 'click_details_proposal_checkout',
  PAY_CREDIT_CARD = 'pay_credit_card',
  PAY_PIX = 'pay_pix',
  PAY_BANKSLIP = 'pay_bankslip',
  CLICK_PAYMENT_RECEIPT = 'click_payment_receipt',
  COPY_PIX = 'copy_pix',
  COPY_BARCODE = 'copy_barcode',
  PAY_ONE_AGREEMENT = 'pay_one_agreement',
  CREATE_MULTIPLE_AGREEMENTS = 'create_multiple_agreements',
  INTRO_MULTIPLE_AGREEMENTS = 'intro_multiple_agreements',
  START_ONE_AGREEMENT = 'start_one_agreement',
  REDIRECT_WHATSAPP = 'redirect_whatsapp',
  HELP_BILL_OVERDUE = 'help_bill_overdue',
  HELP_BILL_DUE = 'help_bill_due',
  HELP_BILL_PAID = 'help_bill_paid',
  BILL_OPEN = 'bill_open',
  BILL_OVERDUE = 'bill_overdue',
  BILL_PAID = 'bill_paid',
  CLICK_ENROLLMENT_INFORMATION = 'click_enrollment_information',
  PAY_ALL_OVERDUE_INSTALLMENT_LINK = 'pay_all_overdue_installment_link',
  CLICK_DETAILS_BILL = 'click_details_bill',
  UPDATE_BILL = 'update_bill',
  CUSTOM_OFFER_PAYMENT_METHOD = 'custom_offer_payment_method',
  CUSTOM_OFFER_CONFIRMATION = 'custom_offer_confirmation',
  CLICK_DETAILS_PROPOSAL_CONFIRM = 'click_details_proposal_confirm',
  CREATE_PAYMENT_OFFER = 'create_payment_offer',
  CONFIRM_NEGOTIATION = 'confirm_negotiation',
  PAY_MULTIPLE_AGREEMENTS = 'pay_multiple_agreements',
  CLICK_DETAILS_AGREEMENT_SELECT_AGREEMENT = 'click_details_agreement_select_agreement',
  SELECT_OVERDUE = 'select_overdue',
  UNSELECT_OVERDUE = 'unselect_overdue',
  TAB_OPEN = 'tab_open',
  TAB_PAID = 'tab_paid',
  CLICK_PAY_BILL_OVERDUE = 'click_pay_bill_overdue',
  ANTICIPATE_BILL = 'anticipate_bill',
  PAY_ALL_OVERDUE_DASHBOARD_CARD_BUTTON = 'pay_all_overdue_dashboard_card_button',
  PAY_PIX_MODAL = 'pay_pix_modal',
  PAY_BANKSLIP_MODAL = 'pay_bankslip_modal',
  CLOSE_BANKSLIP_MODAL = 'close_bankslip_modal',
  SELECT_PAYMENT_OFFER = 'select_payment_offer',
  DEFAULTERS_BANNER = 'defaulters_banner',
  DISCOUNT_CAMPAIGN_BANNER = 'discount_campaign_banner',
  BROKEN_PROMISE_DOWN_PAYMENT_MODAL = 'broken_promise_down_payment_modal',
  CREDIT_CARD_FLOW_START_NEGOTIATION = 'credit_card_flow_start_negotiation',
  CREDIT_CARD_FLOW_VIEW_INSTALLMENT = 'credit_card_flow_view_installment',
  CREDIT_CARD_FLOW_CONFIRMATION = 'credit_card_flow_confirmation',
  CREDIT_CARD_FLOW_NEGOTIATION_SUMMARY = 'credit_card_flow_negotiation_summary',
  CREDIT_CARD_FLOW_SIMULATION_ERROR = 'credit_card_flow_simulation_error',
  INVOICE_LOADING = 'invoice_loading',
  INVOICE_ERROR = 'invoice_error',
  INVOICE_VIEWED = 'invoice_viewed',
  TEACHING_MATERIAL_BANNER_VIEWED = 'teaching_material_banner_viewed',
  TEACHING_MATERIAL_BANNER_FULL_PAGE_VIEWED = 'teaching_material_banner_full_page_viewed',
  TEACHING_MATERIAL_INSTALLMENT_LIST = 'teaching_material_installment_list',
  TEACHING_MATERIAL_CONTINUE_PAYMENT_BUTTON = 'teaching_material_continue_payment_button',
  TEACHING_MATERIAL_BANNER_FULL_PAGE_PAYMENT_BUTTON = 'teaching_material_banner_full_page_payment_button',
  TEACHING_MATERIAL_BANNER_FULL_PAGE_CLOSE_BUTTON = 'teaching_material_banner_full_page_close_button',
  TEACHING_MATERIAL_PIX_RETURN_HOME = 'teaching_material_pix_return_home',
}
