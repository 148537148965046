import type { AuthEventData, AuthPageNames } from '@monorepo/events/src/types';
import { usePostEvents } from '@monorepo/events';
import { EventName, EventScope } from '@monorepo/events/src/types';
export const useSendButtonClickEvent = () => {
  const {
    mutate: postEvent
  } = usePostEvents();
  const sendButtonClickEvent = ({
    identifierName,
    pageName,
    customData
  }: {
    identifierName: string;
    pageName: AuthPageNames;
    customData: AuthEventData;
  }) => {
    postEvent({
      app: 'auth',
      event: EventName.BUTTON_CLICKED,
      scope: EventScope.LOGIN,
      action: 'click',
      identifierName,
      pageName,
      data: {
        button_name: customData.button_name,
        context: customData.context
      }
    });
  };
  return {
    sendButtonClickEvent
  };
};